import './styles/Header.css'
import ReactSelect from "react-select";
import RSOption from "../custom/RSOption";
import { useTranslation } from 'react-i18next';
import { auth } from '../../firebase-config'
import { signOut, onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../../i18n/config';
import { IsAdmin } from '../../context/IsAdmin';

export default function Header() {
  const [lang, setLang] = useState('es');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState<User|null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const languages = [
    { value: "en", label: <RSOption title="English" src={process.env.PUBLIC_URL + "/img/us.svg"} /> },
    { value: "es", label: <RSOption title="Español" src={process.env.PUBLIC_URL + "/img/mx.svg"} /> },
    { value: "fr", label: <RSOption title="Français" src={process.env.PUBLIC_URL + "/img/fr.png"} /> },
    { value: "ar", label: <RSOption title="عرب" src={process.env.PUBLIC_URL + "/img/ar.svg"} /> },
    { value: "pt", label: <RSOption title="Português" src={process.env.PUBLIC_URL + "/img/po.png"} /> },
    { value: "zh-CN", label: <RSOption title="中文" src={process.env.PUBLIC_URL + "/img/ch.png"} /> },
    { value: "ru", label: <RSOption title="ρωσική" src={process.env.PUBLIC_URL + "/img/ru.png"} /> }
  ]

  const onLoadPage = async() => {
    onAuthStateChanged(auth, async(user)=>{
        if (user){
            setUser(user);
            await IsAdmin().then(response => { setIsAdmin(response); });
        }
    });
    setLang(window.localStorage.getItem("language") ?? "en");   
  } 
  const logout = async()=>{
    await signOut(auth);
    navigate('/', { replace: true });
    window.location.reload();
  };
  const changeLanguage = (lan:any) => {
      setLang(lan);
      window.localStorage.setItem("language", lan);
  }

  useEffect(()=> {i18n.changeLanguage(lang)}, [lang])
  useEffect(()=> {onLoadPage()}, []);

  return (
    <div className='header'>
      <a href='/'><img className='logo' src={process.env.PUBLIC_URL + '/img/mfm-white.png'} alt="MFM Logo"></img></a>
        <div className="menu-text">          
          <a href="/pricing">{"Precios"}</a>
          <a href="/information">{t("Courses")}</a>
          <a href="/news">{t("News")}</a>
          <a href="/support">{t("Tecnical Support")}</a>
          <a href={process.env.PUBLIC_URL + '/ORVO-Simulator Installer.exe'}>{"Descargar Simulador"}</a>

          { isAdmin && <div className="dropdown" onMouseEnter={()=>{setMenuOpen(true)}} onMouseLeave={()=>{setMenuOpen(false)}}>
            <input className='drop-btn' type="button" value="Admin" />
            {
              menuOpen && (
                <div className="dropdown-content">
                  <a href="/admin-users">Administrar usuarios</a>
                </div>
              )
            }
          </div>
        }
        </div>
        
        <ReactSelect styles={customStyles} defaultValue={languages.find(l => l.value === (localStorage.getItem("language") ?? "en")) || languages[0]} options={languages} onChange={(e)=>{changeLanguage(e?.value)}} />
        
        <div className='account' >
          { (user != null) && <>
              <img style={{borderRadius:'30px', width:'50px', textAlign:'center'}} src={ user.photoURL ? user.photoURL : process.env.PUBLIC_URL + 'img/accountIcon.png'}/>
              <span onClick={ logout }> {t("Header.Logout")}</span>
          </> }
          { (user == null) && <a style={{color:'white', margin:'10px'}} href='/login'>{t("Header.Login")} </a> }
        </div>
    </div>
  );
}
const customStyles = {
  menu: (provided: any, state: any) => ({ ...provided, width: state.selectProps.width, backgroundColor: "#ffffff6b" }),
  option: (provided: any, state: any) => ({ ...provided, color: 'black', backgroundColor: '', "&:hover": { backgroundColor: "#d8d8d8" }, display: "flex", alignItems: "center", gap: 5 }),
  control: () => ({ width: 150, display: 'flex' }),
  singleValue: (provided: any, state: any) => { 
    const opacity = state.isDisabled ? 0.5 : 1; const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, color: "white", display: 'flex', alignItems: 'center', gap: 5 };
  }
}